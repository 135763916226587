<template>
	<div id="content">
		<div class="erreur404">
			<h1>
				404 <br />
				Page Not Found!
			</h1>
			<a href="/">Retourner à l'accueil ?</a>
		</div>
	</div>
</template>

<script>
export default {
	name: "NotFound",
	metaInfo: function() {
		return {
			title: "404 Not Found",
		};
	},
};
</script>

<style lang="scss">
nav,
footer {
	display: none;
}

body {
	background: no-repeat center center scroll;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	background-image: url("../assets/notfound/fond_notfound.jpg");
}

.erreur404 {
	text-align: center;
	margin-top: 50vh;
	transform: translateY(-50%);
	background-color: rgba(0, 0, 255, 0.3);

	h1 {
		font-family: Heebo;
		font-weight: 900;
		font-size: calc(3vw + 3vh);
		color: #fbfbfb;
	}
	a {
		font-family: Heebo;
		font-weight: 900;
		font-size: calc(2.5vw + 2.5vh);
		color: #e62929;
	}
	a:hover {
		color: #3283c9;
	}
}
</style>
